import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PageModules from '../containers/ModuleContainer'
import Layout from '../components/Layout'

const Page = ({ data, pageContext }) => {
  let { id, locale, hasIndex, slug } = pageContext
  const initialModules = data?.page?.nodes?.[0].modules
  const moduleIds = pageContext.modules
  const seoObj = data?.page?.nodes?.[0]?.seo?.[0] || { locale }
  const defaultShareImage =
    '//images.ctfassets.net/00d5npkzl3wz/3KLK5fiRh6tPbDAf78EX1g/cf6d5f1bc97e7cbedc8dd62f5cb1d412/spacecamp_thumbnail.jpg'

  const seo = {
    id,
    locale,
    metaTitle: seoObj?.title,
    metaDescription: seoObj?.metaDescription,
    slug,
    articleSEO: seoObj?.isPost,
    pageSEO: !seoObj?.isPost,
    ogImage: {
      fixed: {
        width: seoObj?.image?.sourceLandscape?.fixed?.width || 1200,
        height: seoObj?.image?.sourceLandscape?.fixed?.height || 627,
        src: seoObj?.image?.sourceLandscape?.fixed?.src || defaultShareImage,
      },
    },
  }

  const getAlreadyQueriedContentGroupItems = (arr, targetIndex) => {
    let item = arr[targetIndex]

    return item ? item.items : []
  }

  const handleAutomaticItems = (items, index) => {
    let destructuredItems = items?.[0]
    destructuredItems =
      destructuredItems?.contentType === 'ContentfulAutomatic'
        ? getAlreadyQueriedContentGroupItems(moduleIds, index)
        : destructuredItems?.items

    return destructuredItems
  }

  const handleMapDynamicContentGroupModuleQueriesToModules = (
    moduleIds,
    modules
  ) =>
    modules?.map(({ items, feedSource, contentType, ...fields }, index) => ({
      ...fields,
      items:
        items && contentType !== 'ContentfulModuleGallery'
          ? handleAutomaticItems(items, index)
          : feedSource // for cards list automatic
          ? getAlreadyQueriedContentGroupItems(moduleIds, index)
          : items,
      contentType: contentType,
    }))

  const modules = handleMapDynamicContentGroupModuleQueriesToModules(
    moduleIds,
    initialModules
  )

  return (
    <Layout {...pageContext} seo={seo}>
      <PageModules modules={modules} />
      {hasIndex && <button>View more</button>}
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default Page

export const query = graphql`
  query Page($id: String!, $locale: String!) {
    page: allContentfulPage(
      filter: { contentful_id: { eq: $id }, node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        slug
        seo {
          ...seoFragment
        }
        modules {
          ...twoColumnFragment
          ...carouselFragment
          ...itemFragment
          ...cardListFragment
          ...cardListAutomaticFragment
          ...soundcloudFragment
        }
      }
    }
  }
`
